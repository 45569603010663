import * as React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "rsuite/dist/rsuite.min.css";
import Layout from "./components/layout";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import {
  Routes,
  Route,
  Outlet,
  Link,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import Home from "./pages/home";
import Details from "./pages/details";
import Payment from "./pages/payment";
import Done from "./pages/done";
import PrivateRoute from "./utils/ProtectedRoute";
import Dashboard from "./pages/dashboard";
import { UserAuthContextProvider } from "./features/useAuthContextProvider";
import Error from "./pages/error";
import Landing from "./pages/landing";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserAuthContextProvider>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/start" element={<Home />} />
            <Route path="/profile" element={<PrivateRoute />}>
              <Route path="/profile" element={<Dashboard />} />
            </Route>
            <Route path="/details" element={<Details />} />

            <Route path="/error" element={<Error />} />
            <Route path="/done" element={<Done />} />

            <Route path="/payment" element={<PrivateRoute />}>
              <Route path="/payment" element={<Payment />} />
            </Route>
          </Routes>
        </Layout>
      </BrowserRouter>
    </UserAuthContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
