import React, { useContext, useState, useEffect } from "react";
import Lottie from "lottie-react";
import loadingAnimation from "../lottie/KoKsmUDelR.json";


const LoadingIndicator = props => {
      return props.isLoading ? 
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex flex-col items-center">
          <div className="w-36 h-42 p-6 transition-all duration-500 bg-white drop-shadow-2xl rounded-3xl ">
          <div className="flex flex-col items-center text-jaigreen">
          <Lottie animationData={loadingAnimation} loop={true} />
            <h4 className="mt-1">Loading...</h4>
          </div>
        </div>
        </div>
        </div> : '';
    }

  export default LoadingIndicator;