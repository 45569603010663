import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PersonalDetailsForm from '../components/PersonalDetailsForm';
import { useAuthState } from "react-firebase-hooks/auth";
import {
  auth,
  registerWithEmailAndPassword
} from '../api/fuego'
import TOSModal from "../components/TOSModal";
import LoadingIndicator from "../components/LoadingIndicator";
import { useUserAuth } from '../features/useAuthContextProvider';

export default function Details() {

  const navigate = useNavigate();
  const { user } = useUserAuth();

  if(user && user.uid) {
    navigate("/profile");
  }


  const [phoneNumber, setPhoneNumber] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [zip, setZip] = useState();
  const [password, setPassword] = useState();
  const [showTOSModal, setShowTOSModal] = useState(false);
  const [isTOSAccepted, setIsTOSAccepted] = useState(false);
  const [isLoading, setIsLoadng] = useState(false);
  const [addressStreet, setAddressStreet] = useState();
  const [addressUnit, setAddressUnit] = useState();
  const [shirtSize, setShirtSize] = useState();

  return (
    <div className='w-screen'>
      <div className='flex flex-col items-center justify-center flex-1 w-full'>
        <h2 className='text-jaigreen font-caslon'>… and a little more about you …</h2>
        <div>
          <PersonalDetailsForm setShowTOSModal={setShowTOSModal} name={name} email={email} zip={zip} phoneNumber={phoneNumber} shirtSize={shirtSize} addressStreet={addressStreet} addressUnit={addressUnit} setName={setName} setEmail={setEmail} setPhoneNumber={setPhoneNumber} setZip={setZip} setIsLoading={setIsLoadng} setAddressStreet={setAddressStreet} setAddressUnit={setAddressUnit} setShirtSize={setShirtSize} />
          <TOSModal showTOSModal={showTOSModal} setShowTOSModal={setShowTOSModal} isTOSAccepted={isTOSAccepted} setIsTOSAccepted={setIsTOSAccepted}/>
          <LoadingIndicator isLoading={isLoading}/>
        </div>
      </div>
    </div>
  )
}