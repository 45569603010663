import React, { useEffect, useState } from "react";
import { Routes, Route, Outlet, Link, useLocation, matchRoutes } from "react-router-dom";

import jailogo from '../images/jailogoweb.png'
import jailines from '../images/jailines.png'
import { FiSmile, FiUser, FiHeart, FiCreditCard, FiCheck } from "react-icons/fi";
import { Progress } from 'rsuite';
import { BiUserCircle } from "react-icons/bi";
import { LoginModal } from "./loginModal";



const Navbar = () => {

  const [currentProgress, setCurrentProgress] = useState();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { pathname } = useLocation();

  const SUBSCRIBE_STEPS = [
    { text: "Welcome", href: "/", id: "welcome", isActive: true, progress: 5, icon: <FiSmile style={{ fontSize: 20 }} /> },
    { text: "My Details", href: "/details", id: "details", isActive: false, progress: 25, icon: <FiUser style={{ fontSize: 20 }} /> },
    { text: "Payment", href: "/payment", id: "payment", isActive: false, progress: 75, icon: <FiCreditCard style={{ fontSize: 20 }} /> },
    { text: "All Done!", href: "/done", id: "done", isActive: false, progress: 100, icon: <FiCheck style={{ fontSize: 20 }} /> },

  ];

  useEffect(() => {
    setCurrentProgress(SUBSCRIBE_STEPS.filter(item => item.href === pathname)[0]?.progress)
  }, [pathname])

  return (
    <header className='py-4 pt-4 sm:px-10'>
      <div className='flex flex-row h-24 align-center w-full justify-between'>
        <div className='w-64'>
          <img src={jailogo} />
        </div>
        <div className='w-full items-center flex align-center'>
          
        </div>
         <LoginModal/>
      </div>
      <Progress.Line className="drop-shadow-xl" percent={currentProgress} strokeColor="#6c893a" showInfo={false} />
      <div className="flex flex-row flex-nowrap justify-between p-2">
        {SUBSCRIBE_STEPS.map((step) => {
          return <div key={step.id} className="flex flex-row no-underline">
            <div className="px-2 text-jaibrown no-underline">
              {step.icon}
            </div>
            <div className="text-jaibrown"> {step.text} </div>
          </div>
        })}
      </div>
    </header>
  );
};

export default Navbar;