import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Routes, Route, Outlet, Link, useLocation, matchRoutes, useNavigate } from "react-router-dom";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { data } from "autoprefixer";
import { useUserAuth } from '../features/useAuthContextProvider'

const PersonalDetailsForm = (props) => {

    const [isForwardButtonDisabled, setIsForwardButtonDisabled] = useState(false);

    const navigate = useNavigate();

    const [fireErr, setFireErr] = useState("")

    const { signUp } = useUserAuth();

    const { register, formState: { errors, isDirty },
        trigger, handleSubmit, watch, getValues } = useForm({
            mode: "onChange"
        });

        
    const [ tos, firstName, lastName, email, phone, shirtSize, zip, addressStreet, addressUnit, password, passwordConfirmation ] = watch(["tos", 'firstName', 'lastName', 'email', 'phone', 'zip', 'shirtSize', 'addressStreet', 'addressUnit', 'addressCity', 'password', 'passwordConfirmation']);


    const onSubmit = async (data) => {
        props.setIsLoading(true)
        try {
          await signUp(data.firstName, data.lastName, data.email, data.phone, data.zip, data.tos, data.password, data.shirtSize, data.addressStreet, data.addressUnit);
          props.setIsLoading(false)
          navigate('/payment');
        } catch (err) {
          setFireErr(err)
        }
    }

    return <div className="p-6 flex justify-center">
        <div className="">
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="bg-white bg-opacity-70 rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                        <div className="grid grid-cols-1  lg:grid-cols-4 gap-4 lg:gap-8">
                            <div className="lg:col-span-6">
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-7">
                                    <div className="md:col-span-3">
                                        <label htmlFor="first_name">First Name</label>
                                        <input type="text"
                                            name="first_name"
                                            id="first_name"
                                            placeholder="First Name"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={props.name}
                                            onChange={(e) => props.setName(e.target.value)}
                                            {...register("firstName", { required: "First Name is required", maxLength: 45, message: "First Name Field is Required" })}
                                            aria-invalid={errors.name ? "true" : "false"}
                                        />
                                        <p className="text-jaired">{errors?.name?.message}</p>
                                    </div>

                                    <div className="md:col-span-3">
                                        <label htmlFor="last_name">Last Name</label>
                                        <input type="text"
                                            name="last_name"
                                            id="last_name"
                                            placeholder="Last Name"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={props.lastName}
                                            onChange={(e) => props.setName(e.target.value)}
                                            {...register("lastName", { required: "Last Name is required", maxLength: 45, message: "Last Name Field is Required" })}
                                            aria-invalid={errors.name ? "true" : "false"}
                                        />
                                        <p className="text-jaired">{errors?.name?.message}</p>
                                    </div>

                                    <div className="md:col-span-3">
                                        <label htmlFor="email">Email Address</label>
                                        <input type="text"
                                            name="email"
                                            id="email"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={props.email}
                                            placeholder="Your E-Mail"
                                            onChange={(e) => props.setEmail(e.target.value)}
                                            {...register("email", { required: "E-Mail is Required", maxLength: { value: 65, message: "Email is too long" }, minLength: { value: 5, message: "Email is too short" }, required: true, message: "Please check the E-Mail field", pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
                                        <p className="text-jaired">{errors?.email?.message}</p>
                                    </div>

                                    <div className="md:col-span-3">
                                        <label htmlFor="phone">Phone Number</label>
                                        <input
                                            type="text"
                                            name="phone"
                                            id="phone"
                                            className="transition-all flex items-center h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            placeholder="10 digits"
                                            {...register("phone", { required: "Phone Number is Required", minLength: { value: 10, message: "Phone Number must be a 10-digit number" }, maxLength: { value: 10, message: "Phone Number must be a 10-digit number" }, message: "Phone Number must be a 10-digit number" })} />
                                        <p className="text-jaired">{errors?.phone?.message}</p>
                                    </div>

                                    <div className="md:col-span-1">
                                        <label htmlFor="shirtSize">Shirt Size</label>
                                        <select
                                            
                                            name="shirtSize"
                                            id="shirtSize"
                                            className="transition-all flex items-center h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            placeholder="Shirt Size"
                                            value={props.shirtSize}
                                            onChange={(e) => props.setShirtSize(e.target.value)}
                                            {...register("shirtSize", { required: "This field is required" })} >
                                                <option value="XS" >XS</option>
                                                <option value="S" >S</option>
                                                <option value="M" >M</option>
                                                <option value="L" >L</option>
                                                <option value="XL" >XL</option>
                                                <option value="XXL" >XXL</option>
                                                <option value="3XL" >3XL</option>
                                                <option value="4XL" >4XL</option>
                                                <option value="5XL" >5XL</option>
                                            </select>
                                        <p className="text-jaired">{errors?.shirtSize?.message}</p>
                                    </div>


                                    <div className="md:col-span-2">
                                        <label htmlFor="addressStreet">Street Address</label>
                                        <input
                                            type="text"
                                            name="addressStreet"
                                            id="addressStreet"
                                            className="transition-all flex items-center h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            placeholder="123 Main St"
                                            value={props.addressStreet}
                                            onChange={(e) => props.setStreetAddress(e.target.value)}
                                            {...register("addressStreet", { required: "This field is required" })} />
                                        <p className="text-jaired">{errors?.addressStreet?.message}</p>
                                    </div>
                                    <div className="md:col-span-2">
                                        <label htmlFor="addressUnit">Unit #</label>
                                        <input
                                            type="text"
                                            name="addressUnit"
                                            id="addressUnit"
                                            className="transition-all flex items-center h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            placeholder="Apt 123"
                                            value={props.addressUnit}
                                            onChange={(e) => props.setUnitNumber(e.target.value)}
                                            {...register("addressUnit")} />
                                        <p className="text-jaired">{errors?.addressUnit?.message}</p>
                                    </div>

                                    

                                    <div className="md:col-span-2">
                                        <label htmlFor="zipcode">ZIP-code</label>
                                        <input
                                            type="text"
                                            name="zipcode"
                                            id="zipcode"
                                            className="transition-all flex items-center h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            placeholder="00000"
                                            value={props.zip}
                                            onChange={(e) => props.setZip(e.target.value)}
                                            {...register("zip", { required: "Postal Code is Required", minLength: { value: 5, message: "Your postal code must be 5 digits long" }, maxLength: { value: 5, message: "Your postal code must be 5 digits long" }, message: "Your postal code must be 5 digits long" })} />
                                        <p className="text-jaired">{errors?.zip?.message}</p>
                                    </div>
                                    <div className="md:col-span-2">
                                        <label htmlFor="password">Password</label>
                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            className="transition-all flex items-center h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            placeholder="Minimum 6 digits"
                                            {...register("password", { required: "Password cannot be empty", minLength: { value: 6, message: "Your password must be at least 6 digits long" }, maxLength: 64, message: "Your password must be at least 6 digits long" })} />
                                        <p className="text-jaired">{errors?.password?.message}</p>
                                    </div>

                                    <div className="md:col-span-2 ">
                                        <label htmlFor="repeatpassword">Repeat Password</label>
                                        <input type="password"
                                            name="repeatpassword"
                                            id="repeatpassword"
                                            className="transition-all flex items-center h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            placeholder="Minimum 6 digits"
                                            {...register("passwordConfirmation", {
                                                required: "Please confirm password!",
                                                validate: {
                                                    matchesPreviousPassword: (value) => {
                                                        const { password } = getValues();
                                                        return password === value || "Passwords should match!";
                                                    }
                                                }
                                            })} />
                                        {errors.passwordConfirmation && (
                                            <p className="text-jaired">
                                                {errors.passwordConfirmation.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="md:col-span-5 ">
                                        <div className="flex-row flex">
                                        <input
                                            type="checkbox"
                                            id="tos"
                                            name="tos"
                                            value="true"
                                            {...register("tos", { required: "You must accept Terms and Conditions to continue" })} />
                                        <label className="flex flex-row px-2" htmlFor="tos"> I accept <div className={"text-blue-600 underline px-2 hover:cursor-pointer	"} onClick= { 
                                            () => props.setShowTOSModal(true)
                                        }>terms and conditions</div></label><br></br>
                                        </div>
                                        <p className="text-jaired">{errors?.tos?.message}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center items-center">
                    <button type="submit" onClick={() => {
                        if (tos != null && tos === "true" && firstName != null && lastName != null && email != null && phone != null && zip != null && password != null && passwordConfirmation != null && Object.keys(errors).length === 0 ) {
                            
                        }
                    }} disabled={isForwardButtonDisabled} className={`inline-block px-12 py-4 bg-jaigreen text-jaiwhite font-medium text-xs leading-tight uppercase rounded-full shadow-lg  hover:shadow-xl focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out disabled:opacity-20`}>Next Step</button>
                    </div>
                    
                </form>
            </div>
        </div>
    </div>
}

export default PersonalDetailsForm;