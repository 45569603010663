import React, { useEffect, useState } from "react";

export default function TOSModal(props) {

  return (
    <>
      
      {props.showTOSModal ? (
        <>
          <div
            className="justify-center items-center  flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="bg-jaiwhite h-screen relative w-auto my-6 mx-auto max-w-3xl shadow-xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Please Read our Terms and Conditions
                  </h3>
                  <button
                    className="p-1 ml-auto border-0 text-jaigreen float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => props.setShowTOSModal(false)}
                  >
                    <h3 className="bg-transparent h-6 w-6 text-2xl block outline-none focus:outline-none">
                      X
                    </h3>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                  Jai Fusion LLC (dba Jai Jus) Club Jai Membership Program Terms & Conditions

Introduction
This Membership Program is brought to you by Jai Fusion LLC, a company registered in the state of California, United States, doing business as Jai Jus, with a registered address at 470 15th St, San Diego, CA 92101 ("we", "us", "our", "Jai Jus").

By signing up for the Jai Jus Membership Program ("Program"), you agree to be bound by these Terms and Conditions ("Terms"). Please read these Terms carefully. If you do not agree to these Terms, you should not participate in the Program.

Membership
2.1. The annual membership fee for the Program is $89.00, payable upfront and non-refundable, regardless of usage.

2.2. Membership to the Program is on an individual basis only.

2.3. You must be at least 18 years old with a valid email address and phone number to join the Program.

2.4. We reserve the right to refuse or revoke any membership at our sole discretion.

Program Benefits
3.1. Program members are eligible for a 15% discount on Jai Jus merchandise. This discount is applicable to regular priced items only and cannot be combined with any other promotions, discounts, or offers.

3.2. Upon signing up, members may receive a welcome gift. The nature of this gift is at our sole discretion and subject to availability.

3.3. Program members may be granted priority access to try new products. Such access is not guaranteed and is subject to availability and other factors at our sole discretion.

Liability
4.1. To the maximum extent permitted by law, we are not liable for any loss, damage, or injury arising from or related to the Program, regardless of the cause.

4.2. We do not make any warranties or representations, express or implied, about the Program or any products or services provided through it.

Dispute Resolution
5.1. Any dispute arising out of or relating to these Terms, or the breach thereof, shall be settled by arbitration administered by the American Arbitration Association under its Commercial Arbitration Rules, and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof.

Changes to these Terms
6.1. We may amend these Terms at any time, and the updated Terms will be posted on our website. Your continued participation in the Program after such posting constitutes your acceptance of the amended Terms.

Governing Law
7.1. These Terms are governed by and construed in accordance with the laws of the state of California, without regard to its conflict of laws principles.

Communication
8.1. By participating in the Program, you agree that we may communicate with you via email and SMS for any purpose relating to the Program, including but not limited to, program updates, promotional offers, and notifications related to your membership.

8.2. You may opt out of receiving marketing communications at any time by following the unsubscribe instructions provided in the communications. Please note, however, that you cannot opt out of service-related communications such as membership account status and program changes.

8.3. You are responsible for any message or data fees you may be charged by your wireless carrier.

8.4. We respect your privacy and will use your personal information in accordance with our Privacy Policy, which is available on our website.

Please note that these Terms do not affect your statutory rights as a consumer. If you have any questions or concerns about these Terms or the Program, please contact us at the address provided above.

By joining the Program, you confirm that you have read, understood, and accepted these Terms.

Please remember to review these Terms regularly to keep up to date with any changes. Your continued participation in the Program signifies your acceptance of any updated Terms.

Last updated: May 11, 2023
                  </p>
                </div>
                
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}