import Lottie from "lottie-react";
import loadingAnimation from "../lottie/7393-fireworks.json";
import { Link, useNavigate } from 'react-router-dom';
import { useUserAuth } from '../features/useAuthContextProvider';


export default function Done() {

  const {user, userData, isUserPremium } = useUserAuth();
  const navigate = useNavigate();

  if(user && user.uid) {
    navigate("/profile");
  }


  return (
    <div className='w-screen'>
      <div className='flex flex-col items-center justify-center flex-1 w-full'>
      <h2 className='text-jaigreen font-caslon'>Welcome to Club Jai!</h2>
      <h3>Thank you for subscribing</h3>
      <p>We sent you an email with your subscription details</p>
      <Lottie animationData={loadingAnimation} loop={true} />
      </div>
    <button onClick={() => {
      const url = `https://us-central1-jaiadmin-d14c9.cloudfunctions.net/widgets/generatePass?id=${user.uid}&name=${userData.firstName} ${userData.lastName}&disCode=${"TEST"}`
      window.open(url, "_blank");
    }} type="button" className="inline-block px-6 py-2.5 bg-jaigreen text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">ADD TO APPLE WALLET</button>
      <Link to={"/profile"}>
    <button type="button" className="inline-block px-6 py-2.5 bg-jaigreen text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">GO TO MY ACCOUNT</button>
    </Link>
    </div>
  )
}
