import React, { useEffect, useState } from 'react';
import { Modal, Button, ButtonToolbar, Placeholder } from 'rsuite';
import { LockClosedIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import jailogoweb from '../images/jailogoweb.png'
import { useForm } from "react-hook-form";
import { auth} from '../api/fuego';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../features/useAuthContextProvider'


export const LoginModal = () => {


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const { logIn } = useUserAuth();

  const { register, formState: { errors, isDirty },
        trigger, handleSubmit, watch, getValues } = useForm({
            mode: "onChange"
        });

        const onSubmit = async (data) => {
          try {
            await logIn(data.email, data.password);
            handleClose()
            navigate("/profile");
          } catch (err) {
            setError(err)
          }
      };

  return (
    <>
      <div className='flex flex-row justify-end p-4'>
      <ButtonToolbar>
        <Button onClick={() => {
          
          auth.currentUser ? navigate('/profile') : handleOpen()
        }}> <UserCircleIcon className='w-8 h-8 text-jaigreen'/></Button>
      </ButtonToolbar>
      </div>

      <Modal open={open} onClose={handleClose}>
        <Modal.Header>
        </Modal.Header>
        <Modal.Body>
        <div className="flex min-h-full items-center justify-center py-6 px-4 sm:px-6 lg:px-8">
  <div className="w-full max-w-md space-y-8">
    <div>
      <img
        className="mx-auto h-16 w-auto"
        src={jailogoweb}
        alt="Your Company"
      />
      <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
        Sign in to your account
      </h2>
      <p className="mt-2 text-center text-sm text-gray-600">
        Or{' '}
        <a onClick={handleClose} className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer">
          sign up now
        </a>
      </p>
    </div>
    <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" name="remember" defaultValue="true" />
      <div className="-space-y-px rounded-md shadow-sm">
        <div>
          <label htmlFor="email-address" className="sr-only">
            Email address
          </label>
          <input
            id="email-address"
            name="email"
            type="email"
            autoComplete="email"
            required
            className="relative block w-full appearance-none rounded-none rounded-t-md border border-jaibrown px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-jaigreen focus:outline-none focus:ring-jaigreen sm:text-sm"
            placeholder="Email address"
            {...register("email", { required: "E-Mail is Required", maxLength: { value: 65, message: "Email is too long" }, minLength: { value: 5, message: "Email is too short" }, required: true, message: "Please check the E-Mail field", pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
          />
        </div>
        <div>
          <label htmlFor="password" className="sr-only">
            Password
          </label>
          <input
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            required
            className="relative block w-full appearance-none rounded-none rounded-b-md border border-jaibrown px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-jaigreen focus:outline-none focus:ring-jaigreen sm:text-sm"
            placeholder="Password"
            {...register("password", { required: "Password cannot be empty", minLength: { value: 6, message: "Your password must be at least 6 digits long" }, maxLength: 64, message: "Your password must be at least 6 digits long" })}
          />
        </div>
      </div>

      <p className='text-red-500'>{error ? error.message : ""}</p>

      <div className="flex items-center justify-between">
        
        <div className="text-sm">
          <a href="/forgot" className="font-medium text-indigo-600 hover:text-indigo-500">
            Forgot your password?
          </a>
        </div>
      </div>

      <div className='flex justify-center'>
        <button
          type="submit"
          className={`flex flex-row items-center inline-block px-12 py-4 bg-jaigreen text-jaiwhite font-medium text-xs leading-tight uppercase rounded-full shadow-lg  hover:shadow-xl focus:shadow-lg focus:outline-none focus:ring-0 active:bg-jaibrown active:shadow-lg transition duration-150 ease-in-out disabled:opacity-20`}
        >
          <span className=" inset-y-0 left-0 flex items-center pr-3">
            <LockClosedIcon className="h-4 w-4 text-white group-hover:text-indigo-400" aria-hidden="true" />
          </span>
          Sign in
        </button>
      </div>
    </form>
  </div>
</div>
        </Modal.Body>
      </Modal>
    </>
  );
};