import * as React from 'react';
import { useState } from 'react'
import { AiOutlineCheck } from "react-icons/ai";


export default function PlanCard(props) {

const [isSelected, setIsSelected] = useState(true);

return <div className="flex p-8 justify-center">
<div className={`flex flex-col justify-center items-center p-6 rounded-3xl shadow-xl ${isSelected ? "bg-jaigreen" : "bg-white"}  max-w-[250px] h-[300px]`}>
  <h5 className={`${isSelected ? "text-white" : "text-jaibrown"} text-3xl leading-tight mb-2`}>{props.plan.name}</h5>
  <h5 className={`${isSelected ? "text-white" : "text-jaigreen"} text-2xl leading-tight mb-2`}>${`${props.plan.prices[0].unit_amount/100}/year`}</h5>
  <p className={`${isSelected ? "text-white" : "text-jaibrown"} text-base mb-4`}>
    {props.plan.description}
  </p>
  <div className='flex justify-end w-full'>
  <button type="button">
    <div className={`flex justify-center items-center ${isSelected ? "bg-white" : "bg-jaigreen"} h-14 w-14 rounded-full drop-shadow-lg hover:drop-shadow-xl`}>
        <AiOutlineCheck className={`w-8 h-8 ${isSelected ? "text-jaigreen" : "text-white"}`}/>
    </div>
  </button>
  </div>
</div>
</div>
}