import { useEffect, useState } from "react"
import { getStripeProducts } from "../api/fuego"

  export default function useStripeProducts() {
    const [subscriptionProduct, setSubscriptionProduct] = useState()

    useEffect(() => {
         getStripeProducts((items) => {
            setSubscriptionProduct(items[0])
          });
    }, [])

    //console.log(subscriptionProduct)
  
    return subscriptionProduct;
  }
  