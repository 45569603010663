// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
import {
  getStripePayments,
  getProducts,
  createCheckoutSession,
  createPortal,
} from "@stripe/firestore-stripe-payments";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  doc,
  onSnapshot,
  limit,
  setDoc,
  getDoc,
} from "firebase/firestore";
import axios from "axios";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAtZMA3s5-Qgw1HtuqlyJSKInX6G0UjtEk",
  authDomain: "jaiadmin-d14c9.firebaseapp.com",
  projectId: "jaiadmin-d14c9",
  storageBucket: "jaiadmin-d14c9.appspot.com",
  messagingSenderId: "905299270492",
  appId: "1:905299270492:web:f333690fb7ed22f02abf99",
  measurementId: "G-NN2V357DJC",
};

const fuegoApp = initializeApp(firebaseConfig);
const functions = getFunctions(fuegoApp);

const auth = getAuth(fuegoApp);
const storage = getStorage(fuegoApp);

export const payments = getStripePayments(fuegoApp, {
  productsCollection: "products",
  customersCollection: "users",
});

const fuegoDb = getFirestore(fuegoApp);
const usersCol = collection(fuegoDb, "users");
const jobsCol = collection(fuegoDb, "jobApplications");

// Function to add auth with email/pass
const logInWithEmailAndPassword = async (email, password, callback) => {
  try {
    await signInWithEmailAndPassword(auth, email, password).then((user) => {
      callback(user.user);
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

// Allow  e-mail password registration
const registerWithEmailAndPassword = async (
  firstName,
  lastName,
  email,
  phone,
  zip,
  tos,
  password,
  shirtSize,
  addressStreet,
  addressUnit,
  callback
) => {
  const updatedPhone = `+1${phone}`;
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await setDoc(doc(usersCol, user.uid), {
      uid: user.uid,
      firstName,
      lastName,
      authProvider: "local",
      email,
      phone,
      zip,
      tos,
      shirtSize,
      addressStreet,
      addressUnit,
    }).then((docRef) => {
      return true;
    });
  } catch (err) {
    console.error(null, err);
    return false;
  }
};

// Reset Password function
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export const getUserSubscriptionData = async (uid, callback) => {
  const subscriptionCol = collection(fuegoDb, "users", uid, "subscriptions");
  getSubscriptionInformation(subscriptionCol, (subscription) => {
    callback(subscription);
  });
};

export const getSubscriptionInformation = async (subscrCol, callback) => {
  const subscribtionDocs = await getDocs(
    query(subscrCol, where("status", "==", "active"), limit(1))
  );
  var subscr = {};
  subscribtionDocs.forEach((item) => {
    subscr = item.data();
  });
  callback(subscr);
};

// Logout function
const logout = () => {
  signOut(auth);
};

const getUserById = async (uid, callback) => {
  const userProfile = await getDoc(doc(fuegoDb, "users", uid));
  if (userProfile.exists()) {
    callback(userProfile.data());
  } else {
    console.log("Note doesn't exist");
  }
};

const getSubscriptionData = async (callback) => {
  try {
    const res = await axios({
      method: "get",
      url: "https://us-central1-jaiadmin-d14c9.cloudfunctions.net/widgets/getLatestSubscriptionPlan",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    callback(res.data, null);
  } catch (err) {
    console.error(null, err);
  }
};

const addJobApplication = async (
  position,
  firstName,
  lastName,
  email,
  phone,
  resumeUrl,
  availability,
  callback
) => {
  await addDoc(jobsCol, {
    position: position,
    firstName: firstName,
    lastName: lastName,
    email: email,
    phone: phone,
    resumeUrl: resumeUrl,
    availability: availability,
  }).then((res, err) => {
    callback(res, err);
  });
};

const setupUserListener = async (id, callback) => {
  const unsub = onSnapshot(doc(fuegoDb, "users", id), (doc) => {
    callback(doc.data());
  });
};

const getStripeProducts = async (callback) => {
  const products = await getProducts(payments, {
    includePrices: true,
    activeOnly: false,
  });
  callback(products);
};

const createSubsciption = async (subscriptionItem) => {
  const collectionRef = collection(
    fuegoDb,
    "users",
    auth.currentUser.uid,
    "checkout_sessions"
  );
  const docRef = await addDoc(collectionRef, {
    price: subscriptionItem.prices[0].id,
    success_url: window.location.origin + "/done",
    cancel_url: window.location.origin + "/error",
  });

  onSnapshot(docRef, (snap) => {
    const { error, url } = snap.data();
    if (url) {
      window.location.assign(url);
    }
  });
};

export async function goToBillingPortal() {
  const functionRef = httpsCallable(
    functions,
    "ext-firestore-stripe-payments-createPortalLink"
  );

  const { data } = await functionRef({ returnUrl: window.location.origin });
  window.location.assign(data.url);
}

const startSubscriptionWalkthrough = async (subscriptionItem) => {
  createSubsciption(subscriptionItem);
  /*
  session.onSnapshot(async (snap) => {
    const { sessionId } = snap.data();
    if (sessionId) {
      const stripe = await getStripe();
      stripe.redirectToCheckout({ sessionId });
    }
  });
  */
};

export {
  auth,
  fuegoDb,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  getSubscriptionData,
  getUserById,
  setupUserListener,
  getStripeProducts,
  startSubscriptionWalkthrough,
  storage,
  addJobApplication,
};
