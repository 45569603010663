import { Link, useNavigate } from 'react-router-dom';
import SubscriptionFeatures from '../components/SubscriptionFeatures';
import { useUserAuth } from '../features/useAuthContextProvider';


export default function Home() {

  const { user } = useUserAuth();
  const navigate = useNavigate();

  if(user && user.uid) {
    navigate("/profile");
  }

  return (
    <div className='px-6'>
        <div className='flex flex-col items-center justify-center flex-1 w-full'> 
          <SubscriptionFeatures/>
          
          <Link to={"/details"}>
          <button type="button" className="inline-block px-12 py-4 bg-jaigreen text-jaiwhite font-medium text-xs leading-tight uppercase rounded-full shadow-lg  hover:shadow-xl focus:shadow-lg focus:outline-none focus:ring-0 active:bg-jaibrown active:shadow-lg transition duration-150 ease-in-out">Next Step</button>
          </Link>
        </div>
            </div>
  )
}