import React, { useEffect, useState } from "react";
import { Routes, Route, Outlet, Link, useLocation, matchRoutes } from "react-router-dom";
import jailogo from '../images/jailogoweb.png'
import { RxHamburgerMenu } from 'react-icons/rx'
import { useUserAuth } from '../features/useAuthContextProvider';



const AuthedNavbar = () => {

  const [currentProgress, setCurrentProgress] = useState();

  const [navbarOpen, setNavbarOpen] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { pathname } = useLocation();

  const { logOut, user, userData } = useUserAuth();

  return (
    <>
      <nav className="relative flex flex-wrap items-center justify-between px-2 mb-3">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <div className='w-36'>
          <img src={jailogo} />
        </div>
            <button
              className="text-jaibrown cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <RxHamburgerMenu/>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-jaibrown hover:opacity-75"
                  href="/profile"
                >
                  <i className=" text-lg leading-lg text-black"></i><span className="ml-2">Profile</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="px-3 hidden py-2 flex items-center text-xs uppercase font-bold leading-snug text-jaibrown hover:opacity-75"
                  href="/profile/favorites"
                >
                  <i className=" text-lg leading-lg text-black"></i><span className="ml-2">My Favorites</span>
                </a>
              </li>
              <li className="nav-item hidden">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-jaibrown hover:opacity-75"
                  href="/profile"
                >
                  <i className=" text-lg leading-lg text-black"></i><span className="ml-2">Cleanse</span>
                </a>
              </li>
              <li className="nav-item">
                <button onClick={() => {
                  logOut()
                }}
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-jaibrown hover:opacity-75"
                >
                  <i className=" text-lg leading-lg text-black"></i><span className="ml-2">Sign Out</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default AuthedNavbar;

