import { Link, useNavigate } from 'react-router-dom';
import * as React from 'react';
import { useState, useEffect } from 'react'
import PlanCard from '../components/PlanCard';
import { startSubscriptionWalkthrough } from '../api/fuego';
import useStripeProducts from '../features/useStripeProducts';
import LoadingIndicator from '../components/LoadingIndicator';
import { useUserAuth } from '../features/useAuthContextProvider';
import usePremiumStatus from "../features/usePremiumStatus";






export default function Payment() {

  const plan = useStripeProducts()
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const isPremium = usePremiumStatus(user)

  if(isPremium) {
    navigate("/profile");
  }
  

  const [isLoading, setIsLoading] = useState(false)
  
  return <div className='w-screen'>
    <LoadingIndicator isLoading={isLoading}/>
      <div className='flex flex-col items-center justify-center flex-1 w-full'>
        <h2 className='text-jaigreen font-caslon'>Our Plan</h2>
        
        { plan ? <PlanCard plan={plan}/> : ""}
        
        
          <button onClick={() => {
            setIsLoading(true)
            startSubscriptionWalkthrough(plan)
          }} type="button" className="inline-block px-12 py-4 bg-jaigreen text-jaiwhite font-medium text-xs leading-tight uppercase rounded-full shadow-lg  hover:drop-shadow-xl focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-jaibrown active:shadow-lg transition duration-150 ease-in-out">SUBSCRIBE</button>
      </div>

    </div>
}
