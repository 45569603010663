import React, { useEffect, useState } from "react";
import { getUserSubscriptionData, goToBillingPortal } from "../api/fuego";
import { useUserAuth } from "../features/useAuthContextProvider";
import usePremiumStatus from "../features/usePremiumStatus";
import QRCode from "react-qr-code";
import { ReactComponent as GoogleWalletButton } from "../images/googlewallet.svg";
import { ReactComponent as AppleWalletButton } from "../images/applewallet.svg";

const Dashboard = () => {
  const { logOut, user, userData, isUserPremium } = useUserAuth();

  const [subscrInfo, setSubscrInfo] = useState({});

  const isPremium = usePremiumStatus(user);

  useEffect(() => {
    getUserSubscriptionData(user.uid, (subscription) => {
      setSubscrInfo({
        ...subscription,
        memberSince: new Date(
          subscription.created.seconds * 1000
        ).toLocaleDateString("en-US"),
        renewalDate: new Date(
          subscription.current_period_end.seconds * 1000
        ).toLocaleDateString("en-US"),
      });
    });
  }, [user]);

  return (
    <div className="px-4">
      <div>
        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Personal Information
              </h3>
              <p className="mt-1 text-sm text-gray-600"></p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <form
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <div className="overflow-hidden shadow sm:rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        First name
                      </label>
                      <h6>{userData.firstName}</h6>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Last name
                      </label>
                      <h6>{userData.lastName}</h6>
                    </div>

                    <div className="col-span-6 sm:col-span-4">
                      <label
                        htmlFor="email-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <h6>{userData.email}</h6>
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="postal-code"
                        className="block text-sm font-medium text-gray-700"
                      >
                        ZIP / Postal code
                      </label>
                      <h6>{userData.zip}</h6>
                    </div>
                  </div>
                </div>
                <div
                  className={`bg-gray-50 px-4 py-3 text-right sm:px-6 ${
                    isPremium ? "" : "hidden"
                  }`}
                >
                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label
                      htmlFor="qr-code"
                      className="block text-sm font-medium text-gray-700 text-left"
                    >
                      Your member QR code:
                    </label>
                    <div className={"w-24 h-24 p-2"}>
                      {userData.uid ? (
                        <QRCode
                          size={256}
                          style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                          }}
                          value={userData.uid}
                          viewBox={`0 0 256 256`}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="flex justify-center items-center flex-col md:flex-row">
                      <button
                        onClick={() =>
                          window.open(userData.iosPassUrl, "_blank")
                        }
                        type="button"
                        className="w-32 h-12 mx-2 flex items-center justify-center"
                      >
                        <AppleWalletButton className="w-full h-full" />
                      </button>
                      <button
                        onClick={() =>
                          window.open(userData.googlePassUrl, "_blank")
                        }
                        type="button"
                        className="w-56 h-12 mx-2 flex items-center justify-center aspect-w-283 aspect-h-50 hidden" // Set the aspect ratio
                      >
                        <GoogleWalletButton className="w-full h-full" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Your Club Jai Subscription
              </h3>
              <p className="mt-1 text-sm text-gray-600"></p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <form action="#" method="POST">
              <div className="overflow-hidden shadow sm:rounded-md">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <fieldset>
                    <legend className="sr-only">Subscription Status</legend>
                    <div
                      className="text-base font-medium text-gray-900"
                      aria-hidden="true"
                    >
                      Subscription Status
                    </div>
                    {isPremium
                      ? "Member since " + subscrInfo.memberSince
                      : "Inactive"}
                  </fieldset>
                  <fieldset>
                    <legend className="contents text-base font-medium text-gray-900">
                      Your Renewal Date
                    </legend>

                    {subscrInfo.cancel_at_period_end ? (
                      <p className="text-sm text-gray-500">
                        Your subscription will be automatically canceled on{" "}
                        {subscrInfo.renewalDate}. You will not be charged. You
                        will still be able to use your membership until then.
                      </p>
                    ) : (
                      <p className="text-sm text-gray-500">{`Your card on file will be charged on ${subscrInfo.renewalDate}`}</p>
                    )}

                    <div className="mt-4 space-y-4"></div>
                  </fieldset>
                </div>
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <button
                    onClick={(e) => {
                      //window.open(userData.stripeLink, "_blank");
                      e.preventDefault();
                      goToBillingPortal();
                    }}
                    className="inline-block px-6 py-2.5 bg-jaigreen text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-jaibrown hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    Manage Subscription
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
