import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Hero from '../components/Hero';
import SubscriptionFeatures from '../components/SubscriptionFeatures';

export default function Landing() {

    useEffect(() => {

    }, [])

    return (
        <div className=''>
        <Hero/>
        </div>
    )
}