import { BellAlertIcon, CurrencyDollarIcon, GiftIcon, TagIcon, CakeIcon } from '@heroicons/react/20/solid'
import ntv from '../images/nightTrainHd.mp4'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const features = [
    {
        name: 'Buy One - Get One Special',
        description:
            'When you buy a product, you can get the same item on us, once per month',
        icon: TagIcon,
    },
    {
        name: 'Birthday Reward',
        description:
            'A special day requires a special treat - and we got you covered!',
        icon: CakeIcon,
    },
    {
        name: 'Enjoy Member Price',
        description:
            'Our Members get a 15% discount on every order they place in-store or online',
        icon: CurrencyDollarIcon,
    },
    {
        name: 'Get a Welcome Gift',
        description: 'All new Club Jai Members get a generous gift from Jai Jus',
        icon: GiftIcon,
    },
    {
        name: 'Be the First in Line',
        description: 'Club Jai are granted priority access to our upcoming menu items and get a chance to influence our offerings',
        icon: BellAlertIcon,
    },
]


export default function Hero(props) {

    const navigate = useNavigate();

    return (
        <div className="overflow-hidden bg-white ">
            <div className="mx-auto max-w-7xl px-4">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">

<div
                        dangerouslySetInnerHTML={{
                            __html: `<video className="app__backgroundVideo" autoplay muted playsinline>
      <source src=${ntv} type="video/mp4" />
      Your browser does not support the video tag.
</video>`,
                        }}
                    />

                    
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <h2 className="text-lg font-semibold leading-8 tracking-tight text-jaigreen">Club Jai</h2>
                            <p className="mt-2 text-3xl font-bold tracking-tight text-jaibrown sm:text-4xl">Enjoy exclusive member benefits and discounts</p>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                            When you sign up to be a Club Jai Member. You’ll get exclusive benefits that will make it even easier and more fun to enjoy our freshly made, cold-pressed juices and delicious vegan food.
                            </p>
                            <div className="flex justify-center items-center pt-4">
                    <button onClick={() => {
                        navigate('/start');
                    }}  className={`inline-block px-12 py-4 bg-jaigreen text-jaiwhite font-medium text-xs leading-tight uppercase rounded-full shadow-lg  hover:shadow-xl focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out disabled:opacity-20`}>Get Started</button>
                    </div>
                            <dl className="mt-10 max-w-xl space-y-8 text-base leading-0 text-gray-600 lg:max-w-none">
                                {features.map((feature) => (
                                    <div key={feature.name} className="relative pl-9">
                                        <dt className="inline font-semibold text-gray-900">
                                            <feature.icon className="absolute top-1 left-1 h-5 w-5 text-jaigreen" aria-hidden="true" />
                                            {feature.name}
                                        </dt><br/>
                                        <dd className="inline">{feature.description}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}
