import Lottie from "lottie-react";
import loadingAnimation from "../lottie/125952-strawberry-walking-exercise-animation.json";

export default function Error() {

    return (
      <div className='w-screen'>
        <div className='flex flex-col items-center justify-center flex-1 w-full'>
        <h2 className='text-jaigreen font-caslon'>{`Something went wrong :(`}</h2>
        <Lottie animationData={loadingAnimation} loop={true} />
        </div>
      </div>
    )
  }
  