import React, { useEffect, useState } from "react";
import { Routes, Route, Outlet, Link, useLocation, matchRoutes } from "react-router-dom";

import jailogo from '../images/jailogoweb.png'
import { FiSmile, FiUser, FiHeart, FiCreditCard, FiCheck } from "react-icons/fi";
import { RxHamburgerMenu } from 'react-icons/rx'
import { Progress } from 'rsuite';
import { BiUserCircle } from "react-icons/bi";
import { LoginModal } from "./loginModal";



const LandingNavbar = () => {

  const [currentProgress, setCurrentProgress] = useState();

  const [navbarOpen, setNavbarOpen] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { pathname } = useLocation();

  return (
    <>
      <nav className="relative flex flex-wrap items-center justify-between px-2 mb-3 w-screen">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <div className='w-36'>
          <img src={jailogo} />
        </div>
            <button
              className="text-jaibrown cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <RxHamburgerMenu/>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-md font-bold leading-snug text-jaibrown hover:opacity-75"
                  href="/"
                >
                  <i className=" text-lg leading-lg text-black"></i><span className="ml-2">Member Benefits</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-md font-bold leading-snug text-jaibrown hover:opacity-75"
                  href="/start"
                >
                  <i className=" text-lg leading-lg text-black"></i><span className="ml-2">Join Now</span>
                </a>
              </li>
              <li className="nav-item">
              <LoginModal/>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default LandingNavbar;

