import backgroundImage from '../images/jaiwebbg.png';
import fruitbg from '../images/fruitbg.svg'
import Navbar from "./navbar";
import { getToastData } from '../api/toastAPI';
import { useEffect } from 'react';
import { useUserAuth } from '../features/useAuthContextProvider';
import AuthedNavbar from './authedNavbar';
import { Routes, Route, Outlet, Link, useLocation, matchRoutes } from "react-router-dom";
import LandingNavbar from './landingNavbar';
import AdminNavbar from './adminNavbar';


export default function Layout({ children }) {

  const { user, userData, isUserPremium } = useUserAuth();
  const { pathname } = useLocation();
  

  return (
    <div className="overflow-hidden relative">
      <img
        className="opacity-30 absolute bottom-0 right-0 h-96 p-12 h-auto"
        src= {backgroundImage}
        alt=""
      ></img>
      
      <div className="relative">
      <div className="w-screen flex justify-center">
        <div className="relative z-0">
          <div className={`justify-center items-center z-10 py-4 `}>
            
            {pathname == "/" ? <LandingNavbar/> : ""}
            { (pathname != "/" && !pathname.includes("admin")) ? ((user == null || pathname == "/payment" || pathname == "/done") ? <Navbar /> : <AuthedNavbar/>) : ""}
            
            <main>{children}</main>
          </div>
        </div>
      </div>
      </div>
    </div>

  )
}

// <Image className="opacity-20 p-20 w-full h-screen " src={"/jaiwebbg.png"} fill={"cover"}/>