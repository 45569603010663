import React from 'react';
import {Navigate, Outlet} from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useUserAuth } from '../features/useAuthContextProvider';



const  ProtectedRoute=(props) => {
  
  const { user, userData } = useUserAuth();

  return user ? <Outlet/>: <Navigate to="/"/>
}

export default ProtectedRoute;